.nibble {
    margin-bottom: 80px;
}

.nibble h3 {
    font-family: 'Roboto';
    font-weight: 400;
    opacity: 0.8;
    padding-left: 24px;
}

.architects-content {
    z-index: 2;
    position: relative;
}

/* Mobile */
@media only screen and (max-width: 480px) {
    .architects-content h1 {
        max-width: 300px;
    }

    .architects-content  {
        padding-left: 24px;
        padding-right: 24px;
    }

    .architects-content p {
        max-width: 420px;
    }

    .nibble h3 {
        font-size: 16px;
        padding-left: 12px;
        line-height: 24px;
        max-width: 280px;
    }

    .nibble {
        margin-bottom: 40px;
    }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
    .architects-content h1 {
        width: 352px;
    }

    .architects-content p {
        width: 420px;
    }

    .nibble h3 {
        font-size: 16px;
        padding-left: 24px;
        line-height: 24px;
        width: 280px;
    }

    .nibble {
        margin-bottom: 40px;
    }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
    .architects-content h1 {
        width: 500px;
    }

    .architects-content p {
        width: 750px;
    }

    .architects-image {
        margin-top: -100px;
    }
    .nibble h3 {
        font-size: 24px;
        line-height: 36px;
        width: 310px;
    }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {
    .architects-content h1 {
        width: 565px;
    }

    .architects-content p {
        width: 873px;
    }

    .architects-image {
        margin-top: -150px;
    }
    .nibble h3 {
        font-size: 24px;
        line-height: 36px;
        width: 310px;
    }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {
    .architects-content h1 {
        width: 640px;
    }

    .architects-content p {
        width: 1163px;
    }

    .architects-image {
        margin-top: -240px;
    }

    .nibble h3 {
        font-size: 24px;
        line-height: 36px;
        width: 310px;
    }
}